import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faXTwitter,
  faTiktok,
  faYoutube,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Box } from "@mui/material";
import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: "secondary.main",
        p: 3,
        flexDirection: "column",
        alignItems: "center",
        bottom: 0,
      }}
    >
      <Box sx={{ py: 2 }}>
        <a
          href="https://www.facebook.com/stunnersgalleria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon style={{ color: "#fff" }} icon={faFacebook} />
        </a>
        <a
          href="https://twitter.com/stunnersgalleria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faXTwitter}
          />
        </a>
        <a
          href="https://pin.it/7nz6yOu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faPinterest}
          />
        </a>
        <a
          href="https://www.tiktok.com/@stunnersgalleria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faTiktok}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCbCzahqagh5mDG8NwtdKysw"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faYoutube}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/stunners-galleria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faLinkedin}
          />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=237650393369"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faWhatsapp}
          />
        </a>
        <a
          href="mailto:support@stunnersgalleria.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faEnvelope}
          />
        </a>
        <a
          href="https://stunnersgalleria.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faLink}
          />
        </a>
        <a
          href="https://www.instagram.com/stunnersgalleria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            style={{ color: "#fff", paddingLeft: 10 }}
            icon={faInstagram}
          />
        </a>{" "}
      </Box>
      <Box sx={{ color: "#ffffff" }}>
        &copy; {new Date().getFullYear()} Stunners Galleria
      </Box>
    </Box>
  );
};

export default Footer;
