import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";

const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  useEffect(() => {
    const interval = setInterval(nextImage, 5000); // Auto scroll every 10 seconds
    return () => clearInterval(interval);
  }, [nextImage]);

  return (
    <Box className="image-carousel">
      <Box
        mx="auto"
        justifyContent="center"
        alignItems="center"
        display="flex"
        component="img"
        sx={{
          width: { md: "70%", xs: "100%" },
          height: { md: "40vh", xs: "15vh" },
        }}
        src={images[currentImageIndex]}
        alt="Ads"
      />
    </Box>
  );
};

export default ImageCarousel;
