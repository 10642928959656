import React, { useState } from "react";
import { CssBaseline, AppBar, IconButton, Toolbar } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import Survey01 from "./surveys/Survey01";
import Footer from "./components/footer";
import "./App.css";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const customPalette = {
    light: {
      palette: {
        mode: "light",
        primary: {
          main: "#B1740F",
          light: "#E4FDE1",
        },
        secondary: {
          main: "#B1740F",
          light: "#F26430",
        },
        background: {
          default: "#FFFFFF",
          paper: "#FFE45E",
        },
        text: {
          primary: "#2b2929",
          secondary: "rgba(222,219,219,0.7)",
        },
        divider: "rgba(249,202,202,0.12)",
      },
    },
    dark: {
      palette: {
        mode: "dark",
        primary: {
          main: "#B1740F",
          light: "#456990",
        },
        secondary: {
          main: "#000000",
          light: "#525866",
        },
        background: {
          default: "#212529",
          paper: "#333333",
        },
        text: {
          primary: "#F6F4F3", //white smoke
          secondary: "#1d1b1bb3",
        },
        divider: "#1d1b1bb3",
      },
    },
  };

  const theme = createTheme(
    darkMode ? customPalette.dark : customPalette.light
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <ModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        <Survey01 />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

function ModeToggle({ darkMode, toggleDarkMode }) {
  return (
    <AppBar
      sx={{
        bgcolor: "secondary.main",
        px: { md: 5, xs: 1 },
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Box
            component="img"
            src="/main.svg"
            sx={{
              width: "30px",
            }}
            alt="Logo"
          />
        </Box>
        <Box>
          <IconButton onClick={toggleDarkMode}>
            {darkMode ? <Brightness4Icon /> : <WbSunnyIcon />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default App;
