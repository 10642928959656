import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { GoogleFormProvider, useGoogleForm } from "react-google-forms-hooks";
import { DropdownInput, ShortAnswerInput } from "../components/ShortAnswer";
import form from "../scripts/survey_form.json";
import ImageCarousel from "../components/carousel";

export default function Survey01() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");

  const handleFormSubmit = async (data) => {
    await methods.submitToGoogleForms(data);
    methods.reset();
    setIsModalOpen(true);
    setDropdownValue(""); // Reset dropdown value
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const methods = useGoogleForm({ form });
  const handleForm = async () => {
    await methods.handleSubmit(handleFormSubmit)();
  };

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const images = [
    "a.png",
    "b.png",
    "c.png",
    "d.png",
    "e.png",
    "f.png",
    "g.png",
    "h.png",
    "i.png",
  ];
  const blog = ["1.png", "2.png", "3.png", "4.png"];
  return (
    <Box sx={{ py: 9 }}>
      <Box sx={{ px: { md: 24, sm: 4, xs: 3 }, py: 2 }} align="center">
        <Typography variant="p" textAlign="center">
          We'd really appreciate your help with a quick survey. We want to
          understand your needs better so we can make shopping for your personal
          care items easier. Your input means a lot to us. Thank you for taking
          the time to participate! ❤️
        </Typography>
      </Box>
      <a href="https://stunnersgalleria.com" target="_blank" rel="noreferrer">
        <ImageCarousel images={images} />
      </a>
      <GoogleFormProvider {...methods}>
        <Box
          className="box"
          component="form"
          onSubmit={methods.handleSubmit(handleFormSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "primary.light",
            borderRadius: 3,
            mx: { md: 24, sm: 13, xs: 2 },
            my: { md: 5, sm: 3, xs: 4 },
            p: { md: 5, sm: 3, xs: 2 },
          }}
        >
          <Box>
            <ShortAnswerInput id="863807874" />
            <ShortAnswerInput id="319092843" />
            <ShortAnswerInput id="843826290" />
            <ShortAnswerInput id="906174987" />
            <ShortAnswerInput id="815417762" />
            <ShortAnswerInput id="2033160527" />
            <DropdownInput
              id="534734805"
              value={dropdownValue}
              onChange={handleDropdownChange}
            />
            <ShortAnswerInput id="682883596" />
            <ShortAnswerInput id="1909675052" />
            <Button onClick={handleForm} type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </Box>
      </GoogleFormProvider>
      <Box
        sx={{ mb: "30px" }}
        component="a"
        href="https://blog.stunnersgalleria.com"
        target="_blank"
        rel="noreferrer"
      >
        <ImageCarousel images={blog} />
      </Box>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Thank You</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Thank you for taking this survey ❤️
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
