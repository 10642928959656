import {
  Box,
  Typography,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import React from "react";
import {
  useDropdownInput,
  useShortAnswerInput,
} from "react-google-forms-hooks";

export function ShortAnswerInput({ id }) {
  const { register, label } = useShortAnswerInput(id);

  return (
    <Box>
      <Typography variant="p">{label}</Typography> <br />
      <TextField
        type="text"
        {...register()}
        sx={{ mb: 3, width: "100%" }}
        rows={2}
        multiline
      />
    </Box>
  );
}

export function DropdownInput({ id }) {
  const { register, label, options } = useDropdownInput(id);

  return (
    <Box>
      <FormControl>
        <Typography variant="p">{label}</Typography> <br />
        {/* <InputLabel>{label}</InputLabel> */}
        <TextField
          sx={{ mb: 3, width: "100%" }}
          select
          {...register()}
          fullWidth
        >
          {options.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Box>
  );
}
